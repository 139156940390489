import React from 'react';

const Reader = () => {
    return (    
        <svg
            className="ml-auto mt-3"
            width="58"
            height="52"
            viewBox="0 0 62 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="61.622"
                height="56.6929"
                rx="6"
                fill="#F8CFD4"
                fill-opacity="0.2"
            />
            <path
                d="M31 21.2812C34.6675 21.2812 37.6406 18.3081 37.6406 14.6406C37.6406 10.9731 34.6675 8 31 8C27.3325 8 24.3594 10.9731 24.3594 14.6406C24.3594 18.3081 27.3325 21.2812 31 21.2812Z"
                fill="#E2717F"
            />
            <path
                d="M35.8796 22.1809C34.4736 23.0939 32.7977 23.625 31 23.625C29.2023 23.625 27.5264 23.0939 26.1204 22.1809C24.5572 22.7783 23.1172 23.6579 21.872 24.7759L31 28.1989L40.128 24.7759C38.8828 23.6579 37.4428 22.7783 35.8796 22.1809ZM45.0625 32.2188H44.2812C43.6341 32.2188 43.1094 32.7434 43.1094 33.3906V36.5156C43.1094 37.1628 43.6341 37.6875 44.2812 37.6875H45.0625C46.5727 37.6875 47.7969 36.4633 47.7969 34.9531C47.7969 33.443 46.5727 32.2188 45.0625 32.2188ZM17.8325 26.3028C17.6769 26.4107 17.5497 26.5546 17.4618 26.7224C17.374 26.8901 17.3281 27.0767 17.3281 27.266V29.875H17.7188C19.6573 29.875 21.2344 31.4521 21.2344 33.3906V36.5156C21.2344 38.4541 19.6573 40.0313 17.7188 40.0313H17.3281V42.5004C17.3281 42.7389 17.4009 42.9718 17.5368 43.1679C17.6727 43.364 17.8652 43.5139 18.0885 43.5977L29.8281 48V30.2625L18.9115 26.1688C18.7342 26.1022 18.5434 26.0796 18.3555 26.103C18.1676 26.1263 17.9881 26.1949 17.8325 26.3028Z"
                fill="#E2717F"
            />
            <path
                d="M40.7656 36.5156V33.3906C40.7656 31.4521 42.3427 29.875 44.2812 29.875H44.6719V27.266C44.6719 27.0767 44.626 26.8901 44.5382 26.7224C44.4503 26.5546 44.3231 26.4107 44.1675 26.3028C44.0119 26.195 43.8324 26.1264 43.6445 26.1031C43.4566 26.0797 43.2658 26.1023 43.0885 26.1687L32.1719 30.2625V48L43.9115 43.5977C44.1348 43.5139 44.3273 43.364 44.4632 43.1679C44.5991 42.9718 44.6719 42.7389 44.6719 42.5004V40.0312H44.2812C42.3427 40.0312 40.7656 38.4541 40.7656 36.5156ZM18.8906 36.5156V33.3906C18.8906 32.7434 18.3659 32.2188 17.7188 32.2188H16.9375C15.4273 32.2188 14.2031 33.443 14.2031 34.9531C14.2031 36.4633 15.4273 37.6875 16.9375 37.6875H17.7188C18.3659 37.6875 18.8906 37.1628 18.8906 36.5156Z"
                fill="#E2717F"
            />
        </svg>
    )
}

export default Reader;
import React from "react";

const Student = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20 13.2812C23.6675 13.2812 26.6406 10.3081 26.6406 6.64062C26.6406 2.97311 23.6675 0 20 0C16.3325 0 13.3594 2.97311 13.3594 6.64062C13.3594 10.3081 16.3325 13.2812 20 13.2812Z"
                fill="#A7AFB2"
            />
            <path
                d="M24.8796 14.1808C23.4736 15.0939 21.7977 15.625 20 15.625C18.2023 15.625 16.5264 15.0939 15.1204 14.1808C13.5572 14.7783 12.1172 15.6579 10.872 16.7758L20 20.1989L29.128 16.7758C27.8828 15.6579 26.4428 14.7783 24.8796 14.1808ZM34.0625 24.2187H33.2812C32.6341 24.2187 32.1094 24.7434 32.1094 25.3906V28.5156C32.1094 29.1628 32.6341 29.6875 33.2812 29.6875H34.0625C35.5727 29.6875 36.7969 28.4633 36.7969 26.9531C36.7969 25.443 35.5727 24.2187 34.0625 24.2187ZM6.8325 18.3028C6.67687 18.4107 6.54968 18.5546 6.46185 18.7224C6.37401 18.8901 6.32812 19.0766 6.32813 19.266V21.875H6.71875C8.65727 21.875 10.2344 23.4521 10.2344 25.3906V28.5156C10.2344 30.4541 8.65727 32.0312 6.71875 32.0312H6.32813V34.5004C6.32812 34.7389 6.40092 34.9718 6.5368 35.1679C6.67268 35.3639 6.86515 35.5139 7.08852 35.5976L18.8281 40V22.2625L7.91148 18.1687C7.7342 18.1022 7.54341 18.0796 7.35549 18.103C7.16757 18.1263 6.98811 18.1949 6.8325 18.3028Z"
                fill="#A7AFB2"
            />
            <path
                d="M29.7656 28.5156V25.3906C29.7656 23.4521 31.3427 21.875 33.2812 21.875H33.6719V19.266C33.6719 19.0766 33.626 18.8901 33.5382 18.7224C33.4503 18.5546 33.3231 18.4107 33.1675 18.3028C33.0119 18.1949 32.8324 18.1264 32.6445 18.1031C32.4566 18.0797 32.2658 18.1022 32.0885 18.1687L21.1719 22.2625V40L32.9115 35.5976C33.1348 35.5139 33.3273 35.3639 33.4632 35.1679C33.5991 34.9718 33.6719 34.7389 33.6719 34.5004V32.0312H33.2812C31.3427 32.0312 29.7656 30.4541 29.7656 28.5156ZM7.89062 28.5156V25.3906C7.89062 24.7434 7.36594 24.2187 6.71875 24.2187H5.9375C4.42734 24.2187 3.20312 25.443 3.20312 26.9531C3.20312 28.4633 4.42734 29.6875 5.9375 29.6875H6.71875C7.36594 29.6875 7.89062 29.1628 7.89062 28.5156Z"
                fill="#A7AFB2"
            />
        </svg>
    )
}

export default Student;